<div class="modal-content">
	<div class="modal-header">
		<h5 class="modal-title">{{ labels.EOC_MODAL_COMPONENT_TITLE }}</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" style="outline: none"
			(click)="activeModal.dismiss()" ngbAutoFocus>
			<i class="material-icons" style="cursor: pointer;">clear</i>
		</button>
	</div>
	<div class="modal-footer justify-content-between align-items-start flex-nowrap">

		<!-- JPS -->
		<div class="d-flex flex-column w-100 p-3" *ngIf="isJPSVisible">
			<button type="button" class="btn btn-primary pl-3 pr-3 mb-3" (click)="activeModal.close(prodTypeEnum.JPS)"
				[disabled]="!isMechValid">
				{{labels.EOC_MODAL_COMPONENT_JPS}}
			</button>
			<span class="text-dark" innerHTML="{{ labels.EOC_MODAL_COMPONENT_JPS_DESC }}"></span>
			<span class="invalid-feedback d-block" *ngIf="!isMechValid">{{labels.EOC_MODAL_COMPONENT_JPS_WARNING}}</span>
		</div>
		<!-- TL-HEX -->
		<div class="d-flex flex-column w-100 p-3" *ngIf="isTLXVisible">
			<button type="button" class="btn btn-primary pl-3 pr-3 mb-3" (click)="activeModal.close(prodTypeEnum.TLHex)"
				[disabled]="!isAnatValid">
				{{labels.EOC_MODAL_COMPONENT_TLHEX}}
			</button>
			<span class="text-dark" innerHTML="{{ labels.EOC_MODAL_COMPONENT_TLHEX_DESC }}"></span>
			<span class="invalid-feedback d-block" *ngIf="!isAnatValid">{{labels.EOC_MODAL_COMPONENT_TLHEX_WARNING}}</span>
		</div>
		<!-- IWRENCH -->
		<div class="d-flex flex-column w-100 p-3" *ngIf="isIWHVisible">
			<button type="button" class="btn btn-primary pl-3 pr-3 mb-3" (click)="activeModal.close(prodTypeEnum.Iwrench)"
				[disabled]="!isAnatValid">
				{{labels.EOC_MODAL_COMPONENT_IWRENCH}}
			</button>
			<span class="text-dark" innerHTML="{{ labels.EOC_MODAL_COMPONENT_IWRENCH_DESC }}"></span>
			<span class="invalid-feedback d-block" *ngIf="!isAnatValid">{{labels.EOC_MODAL_COMPONENT_TLHEX_WARNING}}</span>
		</div>
		<!-- FITBONE -->
		<div class="d-flex flex-column w-100 p-3" *ngIf="isFITVisible">
			<button type="button" class="btn btn-primary pl-3 pr-3 mb-3" (click)="activeModal.close(prodTypeEnum.Fitbone)"
				[disabled]="!isMechValid">
				{{labels.EOC_MODAL_COMPONENT_FITBONE}}
			</button>
			<span class="text-dark" innerHTML="{{ labels.EOC_MODAL_COMPONENT_FITBONE_DESC }}"></span>
			<span class="invalid-feedback d-block" *ngIf="!isMechValid">{{labels.EOC_MODAL_COMPONENT_FITBONE_WARNING}}</span>
		</div>

	</div>
</div>