import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService, ProductTypeEnum } from '../../core';
import { BaseComponent } from '../../shared';


/**
* Modal component to select correction method
*/
@Component({
	selector: 'app-eoc-modal',
	templateUrl: './eoc-modal.component.html'
})
export class EocModalComponent extends BaseComponent {

	@Input()
	set productList(list: ProductTypeEnum[]) {
		this.isJPSVisible = list?.includes(ProductTypeEnum.JPS);
		this.isTLXVisible = list?.includes(ProductTypeEnum.TLHex);
		this.isIWHVisible = list?.includes(ProductTypeEnum.Iwrench);
		this.isFITVisible = list?.includes(ProductTypeEnum.Fitbone)
	};
	@Input() isMechValid: boolean;
	@Input() isAnatValid: boolean;

	isJPSVisible: boolean;
	isTLXVisible: boolean;
	isIWHVisible: boolean;
	isFITVisible: boolean;

	readonly prodTypeEnum = ProductTypeEnum;

	constructor(
		private readonly langSrv: LanguageService,
		public activeModal: NgbActiveModal
	) {
		super(langSrv);
	}

}
