import { Injectable } from '@angular/core';
import { AxialRotationEnum, BridgeResult, NextRayMeasuresDeformityAP, NextRayMeasuresDeformityLT, SelectedApexAnat, SelectedApexMech, ViewStateType, ViewType } from '@ortho-next/nextray-core';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { Bridge, BridgeActions } from '../../nextray/Core/Bridge';
import { AppModel, BOM, LayerElements, NextrayMeasuresAnatomicalApex, NextrayMeasuresEoCAnat, NextrayMeasuresEoCMech, PlateOrientationType } from '../../nextray/Models/AppModel';
import { AxialTranslationEnum, DeformityAnalysisType } from '../../nextray/Models/Enums';
import { OsteotomyCut, StateDescription, StateTypes, WorkflowRunning } from '../../nextray/States/State';
import { PrintResult } from '../../nextray/Utils/PrintUtils';
import { AnatomicalSideEnum, BoneTypeEnum, Plan, PlanTypeEnum, Plate } from '../core';
import { CaseService } from './case.service';
import { MainCanvasLoaderService } from './main-canvas-loader.service';
import { ModelService } from './model.service';


/**
* This service handles the canvas data.
*/
@Injectable()
export class CanvasService {

	currentCase: Plan;

	private _model: AppModel;
	private _bridge: Bridge;

	private _hasAP: boolean;
	private _hasLT: boolean;

	private _isLongLeg: boolean;
	private _isFemur: boolean;
	private _isTibia: boolean;
	private _isAnkle: boolean;
	private _isForeFoot: boolean;
	private _isHindFoot: boolean;

	private _isLeft: boolean;
	private _isRight: boolean;

	private _isDeformity: boolean;
	private _isFracture: boolean;

	private _isMobile: boolean;

	private _iWrenchAnalysis: boolean;

	private _drawerClosure: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private loaderSrv: MainCanvasLoaderService,
		private caseSrv: CaseService,
		private modelSrv: ModelService
	) {
		this.loaderSrv.canvas3D.subscribe(c => {
			this._model = c.model;
			this._bridge = c.bridge;
		});
		this.caseSrv.getCurrentCase().subscribe(c => {
			this.updateImgStatus(c.apImageGuid, c.ltImageGuid);
			this.updateBoneStatus(c.boneType);
			this.updateSideStatus(c.side);
			this.updatePlanTypeStatus(c.type);
			this.currentCase = c;
			this._iWrenchAnalysis = c.hasIWrench;
		});
		this.checkMobileDevice();
	}

	get isIWrenchAnalysis(): boolean {
		return (this.stateType === StateTypes.tlhex || (this.stateType === StateTypes.EOC && this.deformityAnalysisType === DeformityAnalysisType.anatomical)) && this._iWrenchAnalysis;
	}

	set iWrenchAnalysis(val: boolean) {
		this._iWrenchAnalysis = val;
	}

	/**
	* Get mobile device flag.
	*/
	get isMobile(): boolean {
		return this._isMobile;
	}

	/**
	* Check if current case has AP image.
	*/
	get hasAP(): boolean {
		return this._hasAP;
	}

	/**
	* Check if current case has Lateral image.
	*/
	get hasLT(): boolean {
		return this._hasLT;
	}

	/**
	* Check if current case has Long Leg bone type.
	*/
	get isLongLeg(): boolean {
		return this._isLongLeg;
	}

	/**
	* Check if current case has Femur bone type.
	*/
	get isFemur(): boolean {
		return this._isFemur;
	}

	/**
	* Check if current case has Tibia bone type.
	*/
	get isTibia(): boolean {
		return this._isTibia;
	}

	/**
	* Check if current case has Ankle bone type.
	*/
	get isAnkle(): boolean {
		return this._isAnkle;
	}

	/**
	* Check if current case has ForeFoot bone type.
	*/
	get isForeFoot(): boolean {
		return this._isForeFoot;
	}

	/**
	* Check if current case has HindFoot bone type.
	*/
	get isHindFoot(): boolean {
		return this._isHindFoot;
	}

	/**
	* Check if current case has a Leg bone type.
	*/
	get isLegBone(): boolean {
		return this._isLongLeg || this._isFemur || this._isTibia;
	}

	/**
	* Check if current case has a Foot bone type.
	*/
	get isFootBone(): boolean {
		return this._isAnkle || this._isForeFoot || this._isHindFoot;
	}

	/**
	* Check if current case has Left bone side.
	*/
	get isLeft(): boolean {
		return this._isLeft;
	}

	/**
	* Check if current case has Right bone side.
	*/
	get isRight(): boolean {
		return this._isRight;
	}

	/**
	* Check if current case has Deformity plan type.
	*/
	get isDeformity(): boolean {
		return this._isDeformity;
	}

	/**
	* Check if current case has Fracture plan type.
	*/
	get isFracture(): boolean {
		return this._isFracture;
	}

	/**
	* Get current state description.
	*/
	get stateDescription(): StateDescription {
		return this._model?.stateDescription;
	}

	/**
	* Get current state type.
	*/
	get stateType(): StateTypes {
		return this._model?.stateType;
	}

	/**
	* Get current view state, i.e AP, Lateral or Multiple.
	*/
	get activeViewState(): ViewStateType {
		return this._model?.activeViewState;
	}

	/**
	* Get current active view, i.e AP or Lateral.
	*/
	get activeView(): ViewType {
		return this._model?.activeView;
	}

	/**
	* Get images to create print report
	*/
	get printResult(): PrintResult {
		return this._model?.printResult;
	}

	/**
	* Get image for magnifier
	*/
	get magnifier(): string {
		return this._model?.magnifier;
	}

	/**
	* Get current deformity measures of mechanical axis in AP View.
	*/
	get defAPMeasures(): NextRayMeasuresDeformityAP {
		return this.isDefAPMeasuresVisible() ? this._model?.measures : {};
	}

	private isDefAPMeasuresVisible(): boolean {
		return this.stateType >= StateTypes.deformityAnalysis && this.isAPMechanicalAxisInserted && !(this.activeView === ViewType.AP && this.stateDescription?.isWorkflowRunning === WorkflowRunning.insertMechanicalAxis && this.stateDescription.isPointsPositioningRunning);
	}

	/**
	* Get current deformity measures of mechanical axis in Lateral View.
	*/
	get defLTMeasures(): NextRayMeasuresDeformityLT {
		return this.isDefLTMeasuresVisible() ? this._model?.measures : {};
	}

	private isDefLTMeasuresVisible(): boolean {
		return this.stateType >= StateTypes.deformityAnalysis && this.isLTMechanicalAxisInserted && !(this.activeView === ViewType.LT && this.stateDescription?.isWorkflowRunning === WorkflowRunning.insertMechanicalAxis && this.stateDescription.isPointsPositioningRunning);
	}

	/**
	* Get current deformity measures of contralateral axis.
	*/
	get defCLMeasures(): NextRayMeasuresDeformityAP {
		return this.isDefCLMeasuresVisible() ? this._model?.measures : {};
	}

	private isDefCLMeasuresVisible(): boolean {
		return this.stateType >= StateTypes.deformityAnalysis && this.isContralateralAxisInserted && !(this.activeView === ViewType.AP && this.stateDescription?.isWorkflowRunning === WorkflowRunning.insertContralateral && this.stateDescription.isPointsPositioningRunning);
	}

	/**
	* Get current measures of proximal apex in AP View.
	*/
	get anatApexProxAP(): NextrayMeasuresAnatomicalApex {
		return this._model?.measures?.def_ap_apex1 ? this._model?.measures.def_ap_apex1 : {};
	}

	/**
	* Get current measures of distal apex in AP View.
	*/
	get anatApexDistAP(): NextrayMeasuresAnatomicalApex {
		return this._model?.measures?.def_ap_apex2 ? this._model?.measures.def_ap_apex2 : {};
	}

	/**
	* Get current measures of proximal apex in LT View.
	*/
	get anatApexProxLT(): NextrayMeasuresAnatomicalApex {
		return this._model?.measures?.def_lt_apex1 ? this._model?.measures.def_lt_apex1 : {};
	}

	/**
	* Get current measures of distal apex in LT View.
	*/
	get anatApexDistLT(): NextrayMeasuresAnatomicalApex {
		return this._model?.measures?.def_lt_apex2 ? this._model?.measures.def_lt_apex2 : {};
	}

	/**
	* Get current axial rotation value of anatomical axis in deformity analysis.
	*/
	get defAnatAxialRot(): number {
		return this._model?.def_anatAxialRot;
	}

	/**
	* Get current axial rotation sign of anatomical axis in deformity analysis.
	*/
	get defAnatAxialRotSign(): AxialRotationEnum {
		return this._model?.def_anatAxialRotSign;
	}

	/**
	* Get current axial translation value of anatomical axis in deformity analysis.
	*/
	get defAnatAxialTrans(): number {
		return this._model?.def_anatAxialTrans;
	}

	/**
	* Get current axial translation sign of anatomical axis in deformity analysis.
	*/
	get defAnatAxialTransSign(): AxialTranslationEnum {
		return this._model?.def_anatAxialTransSign;
	}

	/**
	 * Get anatomical axial translation reference.
	 */
	get anatAxialTranslRef(): ViewType {
		return this._model?.anatAxialTranslRef;
	}

	/**
	* Get current axial rotation value of anatomical axis in correction analysis.
	*/
	get eocAnatAxialRot(): number {
		return this._model?.eoc_anatAxialRot;
	}

	/**
	* Get current axial rotation sign of anatomical axis in correction analysis.
	*/
	get eocAnatAxialRotSign(): AxialRotationEnum {
		return this._model?.eoc_anatAxialRotSign;
	}
	/**
	* Get current suggested bone length in correction analysis.
	*/
	get eocSuggestedBoneLength(): number {
		return this._model?.measures?.def_suggestedBoneLength;
	}
	/**
	* Get current over angulation value in correction analysis in AP view.
	*/
	get eocOverAngulationAP(): number {
		return this._model?.measures?.eoc_ap_overAng;
	}
	/**
	* Get current over angulation sign in correction analysis in AP view.
	*/
	get eocOverAngulationSignAP(): string {
		return this._model?.measures?.eoc_ap_overAngSign;
	}
	/**
	* Get current over angulation in correction analysis in LT view.
	*/
	get eocOverAngulationLT(): number {
		return this._model?.measures?.eoc_lt_overAng;
	}
	/**
	* Get current over angulation sign in correction analysis in LT view.
	*/
	get eocOverAngulationSignLT(): string {
		return this._model?.measures?.eoc_lt_overAngSign;
	}
	/**
	* Get current over angulation value in correction analysis in AP view.
	*/
	get eocOverTranslAP(): number {
		return this._model?.measures?.eoc_ap_overTransl;
	}
	/**
	* Get current over angulation sign in correction analysis in AP view.
	*/
	get eocOverTranslSignAP(): string {
		return this._model?.measures?.eoc_ap_overTranslSign;
	}
	/**
	* Get current over angulation value in correction analysis in LT view.
	*/
	get eocOverTranslLT(): number {
		return this._model?.measures?.eoc_lt_overTransl;
	}
	/**
	* Get current over angulation sign in correction analysis in LT view.
	*/
	get eocOverTranslSignLT(): string {
		return this._model?.measures?.eoc_lt_overTranslSign;
	}
	/**
	* Get current correction measures of anatomical analysis.
	*/
	get eocMeasuresAnat(): NextrayMeasuresEoCAnat {
		return this.stateType >= StateTypes.EOC && this.isEocCut && this.deformityAnalysisType === DeformityAnalysisType.anatomical ? this._model?.measures : {};
	}
	/**
	* Get current correction measures.
	*/
	get eocMeasures(): NextrayMeasuresEoCMech {
		return this.stateType >= StateTypes.EOC && this.isEocCut ? this._model?.measures : {};
	}
	/**
	* Get scale factor of AP image.
	*/
	get scaleFactorAP(): number {
		return this._model?.scaleFactorAP;
	}
	/**
	* Get scale factor of Lateral image.
	*/
	get scaleFactorLT(): number {
		return this._model?.scaleFactorLT;
	}
	/**
	* Get current layers state.
	*/
	get layers(): LayerElements {
		return this._model?.layers;
	}
	/**
	* Get current layers values.
	*/
	get layersValue(): LayerElements {
		return this._model?.layersValue;
	}
	/**
	* Check if layers component is visible.
	*/
	get layersVisible(): boolean {
		return this._model?.layersVisible;
	}
	/**
	* Check if mechanical axis is inserted in AP.
	*/
	get isAPMechanicalAxisInserted(): boolean {
		return this._model?.isAPMechanicalAxisInserted;
	}
	/**
	* Check if mechanical axis is inserted in Lateral.
	*/
	get isLTMechanicalAxisInserted(): boolean {
		return this._model?.isLTMechanicalAxisInserted;
	}
	/**
	* Check if contralateral axis is inserted.
	*/
	get isContralateralAxisInserted(): boolean {
		return this._model?.isContralateralAxisInserted;
	}
	/**
	* Check if reference point is inserted.
	*/
	get isReferencePointInserted(): boolean {
		return this._model?.isReferencePointInserted;
	}
	/**
	* Check if anatomical axis is inserted.
	*/
	get isAnatomicalAxisInserted(): boolean {
		return this._model?.isAnatomicalAxisInserted;
	}
	/**
	* Check if third line is inserted.
	*/
	get isThirdLineInserted(): boolean {
		return this._model?.isThirdLineInserted;
	}
	/**
	* Indicates deformity analysis type.
	*/
	get deformityAnalysisType(): DeformityAnalysisType {
		return this._model?.deformityAnalysisType;
	}
	/**
	* Indicates if anatomical axis will sync translation and rotations.
	*/
	get syncAnatomicalAxis(): boolean {
		return this._model?.syncAnatomicalAxis;
	}
	/**
	 * Indicates if apex is too far from axis in AP view. 
	 */
	get apexTooFarAP(): boolean {
		return this._model?.apexTooFarAP;
	}
	/**
	 * Indicates if apex is too far from axis in LT view. 
	 */
	get apexTooFarLT(): boolean {
		return this._model?.apexTooFarLT;
	}
	/**
	 * Indicates if osteotomy is too far in AP view. 
	 */
	get ostTooFarAP(): boolean {
		return this._model?.ostTooFarAP;
	}
	/**
	 * Indicates if osteotomy is too far in LT view. 
	 */
	get ostTooFarLT(): boolean {
		return this._model?.ostTooFarLT;
	}
	/**
	 * Indicates if osteotomy is more inclinated than 15 degrees. 
	 */
	get ostBeyond15Degrees(): boolean {
		return this._model?.ostBeyond15Degrees;
	}
	/**
	 * Indicates if bone length is equals to suggested bone length. 
	 */
	get boneLengthIsEqualsToSuggested(): boolean {
		return this._model?.boneLengthisEqualsToSuggested;
	}
	/**
	* Check if eoc cut is active.
	*/
	get isEocCut(): boolean {
		return this._model?.eocCut;
	}
	/**
	* Get eoc cut type.
	*/
	get osteotomyCut(): OsteotomyCut {
		return this._model?.osteotomyCut;
	}
	/**
	* Get selected apex.
	*/
	get selectedApex(): SelectedApexMech | SelectedApexAnat {
		return this._model?.selectedApex;
	}
	/**
	* Check if plate is inserted.
	*/
	get isPlateInserted(): boolean {
		return this._model?.isPlateInserted;
	}
	/**
	* Get plate orientation type.
	*/
	get plateOrientation(): PlateOrientationType {
		return this._model?.plateOrientation;
	}
	/**
	* Get plate.
	*/
	get plate(): Plate {
		return this._model?.plate ? this._model?.plate.plate : null;
	}
	/**
	* Get JPS Extended status.
	*/
	get hasJPSExtended(): boolean {
		return this._model?.plate ? this._model?.plate.hasJPSExtended : null;
	}
	/**
	* Check if plate is loading in AP view.
	*/
	get isAPPlateLoading(): boolean {
		return this._model?.isAPPlateLoading;
	}
	/**
	* Check if plate is loading in Lateral view.
	*/
	get isLTPlateLoading(): boolean {
		return this._model?.isLTPlateLoading;
	}
	/**
	 * Get screw list for BOM.
	 */
	get screwsBom(): BOM {
		return this._model?.screwsBom;
	}
	/**
	* Get axial roatation value of femur.
	*/
	get def_femur_axialRotation(): number {
		return this.stateType >= StateTypes.deformityAnalysis ? this._model?.def_femur_axialRotation : null;
	}
	/**
	* Get axial roatation sign of femur.
	*/
	get def_femur_axialRotationSign(): AxialRotationEnum {
		return this.stateType >= StateTypes.deformityAnalysis ? this._model?.def_femur_axialRotationSign : null;
	}
	/**
	* Get axial roatation value of tibia.
	*/
	get def_tibia_axialRotation(): number {
		return this.stateType >= StateTypes.deformityAnalysis ? this._model?.def_tibia_axialRotation : null;
	}
	/**
	* Get axial roatation sign of tibia.
	*/
	get def_tibia_axialRotationSign(): AxialRotationEnum {
		return this.stateType >= StateTypes.deformityAnalysis ? this._model?.def_tibia_axialRotationSign : null;
	}
	/**
	* Get standard angle of LPFA.
	*/
	get def_standardAngles_LPFA(): number {
		return this.stateType >= StateTypes.deformityAnalysis ? this._model?.def_standardAngles_LPFA : null;
	}
	/**
	* Get standard angle of mLDFA.
	*/
	get def_standardAngles_mLDFA(): number {
		return this.stateType >= StateTypes.deformityAnalysis ? this._model?.def_standardAngles_mLDFA : null;
	}
	/**
	* Get standard angle of MPTA.
	*/
	get def_standardAngles_MPTA(): number {
		return this.stateType >= StateTypes.deformityAnalysis ? this._model?.def_standardAngles_MPTA : null;
	}
	/**
	* Get standard angle of LDTA.
	*/
	get def_standardAngles_LDTA(): number {
		return this.stateType >= StateTypes.deformityAnalysis ? this._model?.def_standardAngles_LDTA : null;
	}
	/**
	* Get standard angle of PPTA.
	*/
	get def_standardAngles_PPTA(): number {
		return this.stateType >= StateTypes.deformityAnalysis ? this._model?.def_standardAngles_PPTA : null;
	}
	/**
	* Get standard angle of ADTA.
	*/
	get def_standardAngles_ADTA(): number {
		return this.stateType >= StateTypes.deformityAnalysis ? this._model?.def_standardAngles_ADTA : null;
	}
	/**
	 * Cortex Osteotomy level.
	 */
	get print_osteotomyLevel(): number {
		return this._model?.print_osteotomyLevel;
	}
	/**
	 * Cortex Wire insertion point
	 */
	get print_cortexWire(): number {
		return this._model?.print_cortexWire;
	}
	/**
	 * Wire Insertion Angle
	 */
	get print_wireAngle(): number {
		return this._model?.print_wireAngle;
	}

	/**
	 * Get brightness of AP image.
	 */
	public get brightnessAP(): number {
		return this._model?.brightnessAP;
	}

	/**
	 * Get brightness of Lateral image.
	 */
	public get brightnessLT(): number {
		return this._model?.brightnessLT;
	}

	/**
	 * Get contrast of AP image.
	 */
	public get contrastAP(): number {
		return this._model?.contrastAP;
	}

	/**
	 * Get contrast of Lateral image.
	 */
	public get contrastLT(): number {
		return this._model?.contrastLT;
	}

	/**
	 * Gets bone type of Lateral view.
	 */
	public get boneTypeLT(): BoneTypeEnum {
		return this._model?.boneTypeLT;
	}

	/**
	 * Indicates if measurement tool is selected in AP view.
	 */
	public get isMeasurementToolSelectedAP(): boolean {
		return this._model?.isMeasurementToolSelectedAP;
	}

	/**
	 * Indicates if measurement tool is selected in LT view.
	 */
	public get isMeasurementToolSelectedLT(): boolean {
		return this._model?.isMeasurementToolSelectedLT;
	}

	/**
	* Dispatch an event or action.
	*/
	dispatch<K extends keyof BridgeActions>(type: K, args?: BridgeActions[K]): void {
		this._bridge?.mapEvent(type, args);
	}

	/**
	* Add event listener on bridge.
	*/
	addEventListener<K extends keyof BridgeResult>(type: K, listener: (event: Event & { args: BridgeResult[K] }) => void): void {
		this._bridge?.addEventListener(type, listener);
	}

	/**
	* Check if has an event listener on bridge.
	*/
	hasEventListener<K extends keyof BridgeResult>(type: K, listener: (event: Event & { args: BridgeResult[K] }) => void): boolean {
		return this._bridge?.hasEventListener(type, listener);
	}

	/**
	* Remove an event listener on bridge.
	*/
	removeEventListener<K extends keyof BridgeResult>(type: K, listener: (event: Event & { args: BridgeResult[K] }) => void): void {
		this._bridge?.removeEventListener(type, listener);
	}

	/**
	* Dispatch an event listener on bridge.
	*/
	dispatchEvent<K extends keyof BridgeResult>(event: { type: K } & { args: BridgeResult[K] }): void {
		this._bridge?.dispatchEvent(event);
	}

	/**
	* Save current state.
	*/
	saveState(): void {
		this.modelSrv.persistModel(this.currentCase.id, this.currentCase.userGuid, this.isIWrenchAnalysis).subscribe();
	}

	/**
	 * Close drawer.
	 */
	closeDrawer(): void {
		this._drawerClosure.next(true);
	}

	/**
	 * Drawer closure event.
	 */
	drawerClosure(): Observable<boolean> {
		return this._drawerClosure.asObservable().pipe(filter(ev => !!ev));
	}

	private updateBoneStatus(boneType: BoneTypeEnum): void {
		this._isLongLeg = boneType === BoneTypeEnum.LongLeg;
		this._isFemur = boneType === BoneTypeEnum.Femur;
		this._isTibia = boneType === BoneTypeEnum.Tibia;
		this._isAnkle = boneType === BoneTypeEnum.Ankle;
		this._isForeFoot = boneType === BoneTypeEnum.Forefoot;
		this._isHindFoot = boneType === BoneTypeEnum.Hindfoot;
	}

	private updateImgStatus(apImg: string, ltImg: string) {
		this._hasAP = !!apImg && apImg !== '';
		this._hasLT = !!ltImg && ltImg !== '';
	}

	private updateSideStatus(side: AnatomicalSideEnum) {
		this._isLeft = side === AnatomicalSideEnum.Left;
		this._isRight = side === AnatomicalSideEnum.Right;
	}

	private updatePlanTypeStatus(type: PlanTypeEnum) {
		this._isDeformity = type === PlanTypeEnum.Deformity;
		this._isFracture = type === PlanTypeEnum.Fracture;
	}

	private checkMobileDevice(): void {
		this._isMobile = navigator.userAgent && navigator.userAgent.includes('Mobile');
	}
}

export enum BoneImageEnum {
	Left_LongLeg_AP = 'assets/images/bones/bones_left_longleg_AP.gif',
	Left_LongLeg_LT = 'assets/images/bones/bones_left_longleg_LATERAL.gif',
	Right_LongLeg_AP = 'assets/images/bones/bones_right_longleg_AP.gif',
	Right_LongLeg_LT = 'assets/images/bones/bones_right_longleg_LATERAL.gif',

	Left_Femur_AP = 'assets/images/bones/bones_femore_AP_LEFT.gif',
	Right_Femur_AP = 'assets/images/bones/bones_femore_AP_RIGHT.gif',
	Left_Femur_LT = 'assets/images/bones/bones_femore_LT_LEFT.gif',
	Right_Femur_LT = 'assets/images/bones/bones_femore_LT_RIGHT.gif',

	Left_Tibia_AP = 'assets/images/bones/bones_tibia_AP_LEFT.gif',
	Right_Tibia_AP = 'assets/images/bones/bones_tibia_AP_RIGHT.gif',
	Left_Tibia_LT = 'assets/images/bones/bones_tibia_LT_LEFT.gif',
	Right_Tibia_LT = 'assets/images/bones/bones_tibia_LT_RIGHT.gif',
}
