import { Component, OnInit } from '@angular/core';
import { SseService } from '@ortho-next/sse';
import { take, takeWhile, timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { DeformityAnalysisType } from 'src/nextray/Models/Enums';
import { environment } from '../../../environments/environment';
import { LanguageService, LoaderService, ToastService } from '../../core';
import { CanvasService, CaseService, HexrayMapperService, ModelService } from '../../services';
import { BaseComponent } from '../../shared';



/**
* Component of first vertical sidebar tool menu to TLHEX templating.
*/
@Component({
	selector: 'tlhex-menu',
	templateUrl: './tlhex-menu.component.html'
})
export class TlhexMenuComponent extends BaseComponent implements OnInit {

	private _resetKey: string;
	private _isReadOnly: boolean;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private modelSrv: ModelService,
		private toastSrv: ToastService,
		private sseSrv: SseService,
		private loaderSrv: LoaderService,
		private hexrayMapperSrv: HexrayMapperService,
		private caseSrv: CaseService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit(): void {
		this.caseSrv.isReadOnly().pipe(first(res => res != null)).subscribe(res => this._isReadOnly = res);
	}


	/**
	* Display Tlhex template menu tools.
	*/
	enableTemplate(): void {
		if (this.isCorrectionDone) {
			this.canvasSrv.dispatch('switchToTlhex');
			if (!this._isReadOnly) {
				if (this.canvasSrv.currentCase.isCheckUp) {
					this.saveCheckUpEoc();
				} else {
					this.saveModel();
				}
			}
		}
	}

	private saveCheckUpEoc(): void {
		const plan = this.canvasSrv.currentCase;
		const defData = this.hexrayMapperSrv.deformityRequestMapper();
		const eocData = this.hexrayMapperSrv.eocRequestMapper();
		this.loaderSrv.show();
		this.modelSrv.saveRayModelAndTlHexDeformity(plan, defData, eocData).subscribe({
			next: () => this.goToTlhex(),
			error: () => {
				this.loaderSrv.hide();
				this.toastSrv.showWarning(this.labels['TOOLS_MENU_COMPONENT_PLAN_SAVED_ERROR']);
			}
		});
	}

	private saveModel(): void {
		const plan = this.canvasSrv.currentCase;
		this.loaderSrv.show();
		this.handleModelReset();
		this.modelSrv.persistModel(plan.id, plan.userGuid, this.canvasSrv.isIWrenchAnalysis).subscribe({
			error: () => {
				this.backToCorrection();
				this._clearResetEvents();
			}
		});
	}

	private backToCorrection(): void {
		this.toastSrv.showWarning(this.labels['TOOLS_MENU_COMPONENT_PLAN_SAVED_ERROR']);
		this.canvasSrv.dispatch('switchToEOC', DeformityAnalysisType.anatomical);
		this.loaderSrv.hide();
	}

	private _clearResetEvents(): void {
		if (this._resetKey) {
			this.sseSrv.unsubscribe(this._resetKey);
			this._resetKey = null;
		}
	}

	private handleModelReset(): void {
		this._resetKey = `OrthoNext.Tlhex.Reset.${this.canvasSrv.currentCase.id}`;
		console.log('Resetting... ' + this._resetKey);
		this.sseSrv.subscribe(this._resetKey, (success: boolean) => {
			console.log('Resetted: ' + success);
			if (success) {
				this.goToTlhex();
			} else {
				this.backToCorrection();
				this._clearResetEvents();
			}
		});
		// timeout reset subscription
		timer(0, environment.resetSubscriptionTimeout / 10).pipe(
			takeWhile(() => !!this._resetKey),
			take(10),
		).subscribe({
			complete: () => {
				if (this._resetKey) {
					console.log('Not Resetted');
					this.backToCorrection();
					this._clearResetEvents();
				}
			}
		});
	}

	/**
	* Redirect to tlhex ray site.
	*/
	goToTlhex(): void {
		const currentCase = this.canvasSrv.currentCase;
		window.location.href = environment.tlhexRaySite + '?caseGuid=' + currentCase.id;
	}

}
