export const environment = {
  name: 'development',
  production: true,
  insightKey: '903910d4-753d-4922-a13f-4ed16ce2bb58',
  domainPatientAPI: 'https://ofix-to-gtw-locked.azure-api.net/pat/v1',
  domainUserAPI: 'https://ofix-to-gtw-locked.azure-api.net/usr/v1',
  domainImageAPI: 'https://ofix-to-gtw-locked.azure-api.net/img/v1',
  domainRayAPI: 'https://ofix-to-gtw-locked.azure-api.net/ray/v1',
	domainTlhexAPI: 'https://ofix-to-gtw-locked.azure-api.net/tlhex/v1',
	domainIntegrationAPI: 'https://ofix-to-gtw-locked.azure-api.net/int/v1',
	sseAPI: 'https://ofix-to-gtw-locked.azure-api.net/sse',
  cdnUrl: 'https://cdndev.orthonext.app/dev',
  b2cTenantName: 'tlhexdevIDP',
  b2cTenantId: '8ccea850-ae88-4811-a8ea-cf0e7cbcc2ee',
  b2cSignInPolicyId: 'B2C_1_OnlySignIN2',
  b2cResetPasswordPolicyId: 'B2C_1_passwordreset1',
  b2cChangePasswordPolicyId: 'B2C_1A_PasswordChange',
  b2cClientId: 'a4d1d8aa-4339-4d92-824f-4cc5f3a69605',
  b2cApplicationScope: 'openid profile https://tlhexdevIDP.onmicrosoft.com/ofixdev/user_impersonation',
  appSSOutUriApi: 'https://ofix-to-gtw-locked.azure-api.net/services/appssouturi',
  idleTimeToEndSession: 1800,
  buildVer: 'OrthoNext-Ray FE DEV CI-CD_24345.01',
  patplanSite: 'https://patdev.orthonext.app/list/plans',
	planDetailsSite: 'https://patdev.orthonext.app/plan/edit?planGuid=',
  legalTermsSite: 'https://requestdev.orthonext.app/legal-terms',
  aboutSite: 'https://cdndev.orthonext.app/dev/about/about-en.html',
  guideSite: {
    baseUrl: 'https://cdndev.orthonext.app/dev/Guide',
    list: 'https://cdndev.orthonext.app/dev/Guide/guide_list.json'
  },
  helpSite: {
    baseUrl: 'https://cdndev.orthonext.app/dev/Help/NextRay20',
    fileNames: 'https://cdndev.orthonext.app/dev/Help/NextRay20/file_names.json'
  },
	eifuSite: 'https://ifu.orthofix.it',
  templatesSite: 'https://fitbonedev.orthonext.app',
	tlhexRaySite: 'https://tlhexdev.orthonext.app/hexray',
  rayModelVersion: '1.1',
	resetSubscriptionTimeout: 30000,
	cookieName: '_iub_cs-67063076'
};
