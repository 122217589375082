import { Patient } from "./patient-base";


/**
* Plan model
*/
export interface Plan {
	id: string;
	patientId: string;
	boneType: BoneTypeEnum;
	version: string;
	status: string;
	number: string;
	name?: string;
	userGuid: string;
	type: PlanTypeEnum;
	referenceType: ReferenceTypeEnum;
	side: AnatomicalSideEnum;
	isPostOperative: boolean;
	surgeryDate?: Date;
	notes?: string;
	apImageGuid?: string;
	ltImageGuid?: string;
	isReceived?: boolean;
	patient: Patient;
	isCheckUp?: boolean;
	hasIWrench: boolean;
}

export enum AnatomicalSideEnum {
	Left = "Left",
	Right = "Right"
}

export enum BoneTypeEnum {
	LongLeg = "Long Leg",
	Femur = "Femur",
	Tibia = "Tibia",
	Ankle = "Ankle",
	Forefoot = "Forefoot",
	Hindfoot = "Hindfoot"
}

export enum ReferenceTypeEnum {
	Proximal = "Proximal",
	Distal = "Distal"
}

export enum PlanTypeEnum {
	Deformity = "Deformity",
	Fracture = "Fracture"
}

export enum BoneViewEnum {
	AP = "AP",
	LAT = "LAT"
}
