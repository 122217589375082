import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../core';
import { CanvasService, CaseService, ModelService } from '../../services';
import { BaseComponent } from '../base-component';


/**
* Modal component to confirm before exit from ray site
*/
@Component({
	selector: 'app-closure-modal',
	templateUrl: './closure-modal.component.html'
})
export class ClosureModalComponent extends BaseComponent implements OnInit {

	isReadOnly: boolean;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private modelSrv: ModelService,
		private caseSrv: CaseService,
		public activeModal: NgbActiveModal
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.caseSrv.isReadOnly().subscribe(res => this.isReadOnly = res);
	}

	get isSaveDisabled(): boolean {
		return this.isReadOnly || this.isMechAxisWorkflow;
	}

	/**
	* Close site without save current state
	*/
	closeWithoutSave(): void {
		window.location.href = environment.patplanSite;
	}

	/**
	* Close site and save current state
	*/
	closeWithSave(): void {
		this.caseSrv.getCurrentCase().pipe(
			switchMap(_case => this.modelSrv.persistModel(_case.id, _case.userGuid, this.canvasSrv.isIWrenchAnalysis))
		).subscribe(() => window.location.href = environment.patplanSite);
	}

}
